import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["input"]
    connect(){
        if(this.inputTarget.value != ''){
            this.inputTarget.classList.add('has-value')
        }
    }

    focus(){
        this.inputTarget.classList.add('has-value')
    }
}
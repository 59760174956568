import { Controller } from "stimulus";

export default class extends Controller {

    showBox(e) {
        var allBtns = e.target.closest('.btns-container').querySelectorAll('.button')
        allBtns.forEach(el => {
            el.classList.remove('active')
        });
        e.target.classList.add('active')
        
        var allTables = document.querySelectorAll('.tables-container .table')
        allTables.forEach(el => {
            el.classList.remove('active')
            if (el.id == e.target.dataset.tableId) {
                el.classList.add('active')
            }
        });
    }
}
import { Controller } from "stimulus";

export default class extends Controller {
    connect(){
        var that = this
        var el = this.element.querySelector('.currency-input')
        if( el.value != '' ){
            that.convertCurrencyElement(el)
        }
    }
    convertCurrencyElement(el) {
        var currencyInput = el
        var val = currencyInput.value
        val = val.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if (val == '$ ' || val == '') {
            val = ''
        } else {
            val = '$ ' + val
        }
        currencyInput.value = val
    }
    convertCurrency(event) {
        var currencyInput = event.target
        var val = currencyInput.value
        val = val.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        if (val == '$ ' || val == '') {
            val = ''
        } else {
            val = '$ ' + val
        }
        currencyInput.value = val
    }

}
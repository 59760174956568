import { Controller } from "stimulus"

export default class extends Controller {
    show(e) {
        var input = e.target.closest('.password-box').querySelector('input');
        if (input.type === "password") {
            input.type = "text";
            e.target.classList.add('show')
        } else {
            input.type = "password";
            e.target.classList.remove('show')
        }
    }
}
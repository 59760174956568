import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "wizard",

    "answer",

    "errorMessage",

    "form",

    "template",

    "repeater",

    "repeaterBox",
  ];

  static values = {
    backUrl: String,
    add: String,
    fieldsUrl: String,
    deleteUrl: String,
    product: String,
  };

  connect() {
    this.setQuestionHeight();

    var that = this;

    // display first question

    var firstQuestion = this.wizardTarget.querySelector(".question-block");

    firstQuestion.dataset.displayed = true;

    firstQuestion.classList.add("show");

    // Display Repeater for distribution

    if (document.querySelector('[data-wizard-target="repeater"]')) {
      if (
        document.querySelector('[data-wizard-target="repeater"]').dataset
          .questionStatus == "false"
      ) {
        that.distribution(firstQuestion);
        // that.backupDistribution(firstQuestion)
      }
    }

    document.querySelectorAll("input[type=text]").forEach((el) => {
      el.addEventListener("blur", function (e) {
        if (el.value != "") {
          that.next(e);
        }
      });
    });
  }

  setQuestionHeight(e) {
    if (document.querySelector(".wizard-page .question-side .question-page")) {
      var header = document.querySelector(
        ".wizard-page .question-side .question-page div.page-body"
      );
      var qContainer = document.querySelector(
        ".wizard-page .question-side .question-page .question-container"
      );

      qContainer.style.height =
        "calc(100vh - 100px - 5rem - " + header.clientHeight + "px)";
    }
  }

  next(e) {
    this.setQuestionHeight();
    e.preventDefault();
    var that = this;
    if (!this.validateAnswer()) return;

    this.errorMessageTarget.innerText = "";

    this.errorMessageTarget.classList.remove("error-message", "alert-success");

    var currentQuestion = document.querySelector(".question-block.show");
    if (currentQuestion) {
      if (currentQuestion.dataset.questionName == "email_address") {
        var mailformat =
          /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.+])+\.([A-Za-z]{2,4})$/;
        var emailVal = currentQuestion.querySelector(".input-field");
        if (!emailVal.value.match(mailformat)) {
          emailVal.focus();
          this.errorMessageTarget.classList.add("error-message", "alert");
          this.errorMessageTarget.innerHTML =
            "Please enter a valid email. <div class='close-btn'></div>";
          return false;
        }
      }
      if (currentQuestion.dataset.questionName == "leave_estate_to") {
        that.leaveEstateToQuestions(currentQuestion);
        return false;
      }
      if (
        currentQuestion.dataset.questionName == "choices_predecease" ||
        currentQuestion.dataset.questionName == "backup_choices_predecease"
      ) {
        this.formTarget.submit();
        return false;
      }
      if (
        currentQuestion.dataset.questionName ==
        "receiver_of_assets_after_spouse"
      ) {
        that.receiver_of_assets_after_spouse(currentQuestion);
        return false;
      }
    }

    var repeaterQuestion = document.querySelector(".repeater.show");
    if (repeaterQuestion) {
      if (document.querySelector('[name="child_relationship[]"]')) {
        var allAnswers = document.querySelectorAll(
          '[name="child_relationship[]"]'
        );

        var res;

        allAnswers.forEach((el) => {
          var val = el.value;

          if (val == 1 || val == 2) {
            res = true;
          }
        });

        if (res != true) {
          this.formTarget.submit();

          return;
        }
      }
      if (
        repeaterQuestion.querySelectorAll('[name="heirs_percentage[]"]') &&
        document.querySelector('div[data-question-name="choices_predecease"]')
      ) {
        that.gotoQuestion(repeaterQuestion, "choices_predecease");
        return false;
      } else if (
        repeaterQuestion.querySelectorAll('[name="heirs_percentage[]"]') &&
        document.querySelector(
          'div[data-question-name="backup_choices_predecease"]'
        )
      ) {
        that.gotoQuestion(repeaterQuestion, "backup_choices_predecease");
        return false;
      }
    }

    if (document.querySelector('[name="heirs_percentage[]"]')) {
      var heirs_percentage = document.querySelectorAll(
        '[name="heirs_percentage[]"]'
      );

      var sum = 0;

      heirs_percentage.forEach((el) => {
        sum = sum + parseFloat(el.value);
      });

      if (sum > 100 || sum < 100) {
        this.errorMessageTarget.classList.add("error-message", "alert");

        this.errorMessageTarget.innerHTML =
          "Total shares must be equal to 100%! <div class='close-btn'></div>";

        return;
      }
    }

    if (currentQuestion == null) {
      if (repeaterQuestion == null) {
        this.formTarget.submit();
        return;
      } else {
        this.displayNextQuestion(repeaterQuestion);
      }
    } else {
      var currentQuestionName = currentQuestion.dataset.questionName;
      // if (
      //     (currentQuestionName == "leave_estate_to_each_other" &&
      //         currentQuestion.querySelector(
      //             '[data-wizard-target="answer"]:checked'
      //         ).value == "false") ||
      //     (currentQuestionName == "leave_estate_to_children" &&
      //         currentQuestion.querySelector(
      //             '[data-wizard-target="answer"]:checked'
      //         ).value == "false") ||
      //     (currentQuestionName == "receiver_of_assets_after_children" &&
      //         currentQuestion.querySelector(
      //             '[data-wizard-target="answer"]:checked'
      //         ).value == "true")
      // ) {
      //     this.distribution(currentQuestion)

      //     return
      // }
      var input = currentQuestion.querySelector(
        '[data-wizard-target="answer"]:checked'
      );
      // in case the input is a select list
      if (input == null) {
        input = currentQuestion.querySelector('[data-wizard-target="answer"]');
      }
      if (currentQuestionName == "children_distribution_age") {
        var nextQuestion = document.querySelector(
          '[data-question-name="receiver_of_assets_after_children"]'
        );

        if (nextQuestion) {
          currentQuestion.classList.remove("show");
          nextQuestion.classList.add("show");
          nextQuestion.dataset.displayed = true;
          return;
        } else {
          this.formTarget.submit();
          return;
        }
      }
      if (
        currentQuestionName == "receiver_of_assets_after_children" &&
        input.type == "radio" &&
        input.checked == true &&
        input.value == "true"
      ) {
        that.distribution(currentQuestion);
      } else if (
        currentQuestionName == "receiver_of_assets_after_children" &&
        input.type == "radio" &&
        input.checked == true &&
        input.value == "false"
      ) {
        this.formTarget.submit();
        return;
      } else if (
        currentQuestionName == "leave_estate_to_children" &&
        input.type == "radio" &&
        input.checked == true &&
        input.value == "false"
      ) {
        that.distribution(currentQuestion);
      } else if (
        (input.type == "radio" && input.checked == true) ||
        input.type == "text" ||
        input.tagName == "TEXTAREA"
      ) {
        this.displayChildQuestion(currentQuestion, input.value);
      } else if (input.tagName == "SELECT") {
        if (input.dataset.action) {
          var action =
            input.dataset.action.replace("change->wizard#", "this.") + "()";

          eval(action);
        } else {
          this.displayNextQuestion(currentQuestion);
        }
      } else {
        this.displayNextQuestion(currentQuestion);
      }
    }
  }

  getAnswer() {
    var that = this;

    var answers = [];

    var question = document.querySelector(".question-block.show");

    var elements;

    if (question == null) {
      elements = document.querySelectorAll(
        '[data-wizard-target="repeaterBox"] [data-wizard-target="answer"]'
      );
    } else {
      elements = question.querySelectorAll('[data-wizard-target="answer"]');
    }

    elements.forEach((el, i) => {
      var answer = -1;

      if (el.type == "radio" && that.isVisible(el)) {
        if (el.checked == true) {
          answer = el.value;

          answers.push(answer);
        }
      } else if (
        (el.type == "text" || el.type == "date") &&
        el.dataset.optional != "true" &&
        that.isVisible(el)
      ) {
        if (el.value != "") {
          answer = el.value;
        }

        answers.push(answer);
      } else if (el.tagName == "SELECT" && that.isVisible(el)) {
        if (el.value != "") {
          answer = el.value;
        }

        answers.push(answer);
      } else if (el.dataset.optional != "true" && that.isVisible(el)) {
        if (el.value != "") {
          answer = el.value;
        }

        answers.push(answer);
      }
    });

    if (answers.includes(-1) || answers.includes("") || answers.length == 0) {
      return -1;
    } else {
      return true;
    }
  }

  validateAnswer() {
    var answer = -1;

    answer = this.getAnswer();

    if (answer == -1 || answer == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");

      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";

      return false;
    } else {
      return true;
    }
  }

  displayChildQuestion(parent, value = "true") {
    var that = this;

    var tooltip = document.querySelector(".tooltip-menu");

    var repeaterTemplate = document.querySelector(
      'template[data-name="' + parent.dataset.questionName + '"]'
    );

    if (tooltip != null) {
      tooltip.classList.remove("active");
    }

    var nextQuestion = this.findChildQuestion(parent, value);

    if (repeaterTemplate != null && value == "true") {
      that.setUpTemplateFields(parent, repeaterTemplate);
    } else if (nextQuestion) {
      parent.classList.remove("show");

      nextQuestion.dataset.displayed = true;

      nextQuestion.classList.add("show");
    } else {
      this.displayNextQuestion(parent);
    }
  }

  findChildQuestion(question, value = "true") {
    var input = question.querySelector('[data-wizard-target="answer"]');
    if (input.type != "radio") {
      value = "true";
    }
    var childQuestion = document.querySelector(
      `.question-block[data-parent-id="${question.dataset.questionId}"][data-displayed="false"][data-skip="false"][data-parent-answer="${value}"]`
    );

    var parentQuestion = document.querySelector(
      '.question-block[data-question-id="' + question.dataset.parentId + '"]'
    );
    if (childQuestion && childQuestion.dataset.skip != "true") {
      return childQuestion;
    } else if (parentQuestion && parentQuestion.dataset.skip != "true") {
      return this.findChildQuestion(parentQuestion);
    } else {
      return null;
    }
  }

  displayNextQuestion(currentQuestion) {
    var nextQuestion = this.findNextQuestion(currentQuestion);

    var tooltip = document.querySelector(".tooltip-menu");

    if (nextQuestion == null) {
      this.formTarget.submit();
      return;
    }

    // check if you are on repeator block

    if (
      nextQuestion.classList.contains("repeater") ||
      nextQuestion.classList.contains("repeater-box")
    ) {
      prevQuestion = document.querySelector(
        '[data-question-name="' +
          that.repeaterTarget.dataset.prevQuestion +
          '"]'
      );

      that.repeaterTarget.classList.add("show");

      that.repeaterBoxTargets.forEach(function (el) {
        el.classList.add("show");
      });
    }

    if (tooltip != null) {
      tooltip.classList.remove("active");
    }

    if (nextQuestion) {
      currentQuestion.classList.remove("show");

      nextQuestion.classList.add("show");

      nextQuestion.dataset.displayed = true;
    } else {
      this.formTarget.submit();
      return;
    }
  }

  findNextQuestion(currentQuestion) {
    var nextQuestion = currentQuestion.nextElementSibling;

    if (nextQuestion && nextQuestion.tagName == "TEMPLATE") {
      nextQuestion = nextQuestion.nextElementSibling;
    }

    var input = currentQuestion.querySelector(
      '[data-wizard-target="answer"]:checked'
    );

    var repeaterQuestion = document.querySelector(".repeater.show");

    this.errorMessageTarget.innerText = "";

    this.errorMessageTarget.classList.remove("error-message");

    if (nextQuestion) {
      if (
        nextQuestion.dataset.skip == "special" &&
        currentQuestion.dataset.parentId != "" &&
        input &&
        input.value == "true"
      ) {
        return nextQuestion;
      } else if (currentQuestion == repeaterQuestion) {
        return nextQuestion;
      } else if (
        nextQuestion.dataset.parentId == "" &&
        nextQuestion.dataset.skip != "true"
      ) {
        return nextQuestion;
      } else {
        return this.findNextQuestion(nextQuestion);
      }
    } else {
      return null;
    }
  }

  gotoQuestion(currentQuestion, questionName) {
    var that = this;
    var question = document.querySelector(
      `div[data-question-name="${questionName}"]`
    );
    if (question) {
      currentQuestion.classList.remove("show");
      question.classList.add("show");
      question.dataset.displayed = true;
    } else {
      that.formTarget.submit();
      return false;
    }
  }

  prev(e) {
    this.setQuestionHeight();
    e.preventDefault();

    var that = this;
    var currentQuestionName = null;
    var currentQuestion = document.querySelector(".question-block.show");

    if (currentQuestion)
      currentQuestionName = currentQuestion.dataset.questionName;

    var prevQuestion = this.findPrevQuestion(currentQuestion);

    this.errorMessageTarget.innerHTML = "";

    this.errorMessageTarget.classList.remove("error-message", "alert-success");

    if (currentQuestionName == "children_distribution_age") {
      var prevQuestion = document.querySelector(
        '[data-question-name="children_distribution_option"]'
      );

      if (prevQuestion) {
        currentQuestion.classList.remove("show");

        prevQuestion.classList.add("show");

        prevQuestion.dataset.displayed = true;

        return;
      } else {
        this.formTarget.submit();
        return;
      }
    }

    if (prevQuestion) {
      if (prevQuestion.tagName == "TEMPLATE") {
        prevQuestion = this.findPrevQuestion(prevQuestion);
      }
    }

    // check if you are on repeator block

    if (
      currentQuestion == null ||
      currentQuestion.classList.contains("repeater") ||
      currentQuestion.classList.contains("repeater-box")
    ) {
      prevQuestion = document.querySelector(
        '[data-question-name="' +
          that.repeaterTarget.dataset.prevQuestion +
          '"]'
      );

      that.repeaterTarget.classList.remove("show");

      that.repeaterBoxTargets.forEach(function (el) {
        el.classList.remove("show");
      });
    }

    if (prevQuestion) {
      if (currentQuestion) {
        currentQuestion.classList.remove("show");

        currentQuestion.dataset.displayed = false;
      }

      prevQuestion.classList.add("show");
    } else {
      document.location.href = this.backUrlValue;
    }
  }

  findPrevQuestion(currentQuestion) {
    if (currentQuestion == null) {
      var prevQuestion = null;
    } else {
      var prevQuestion = currentQuestion.previousElementSibling;
    }

    if (prevQuestion) {
      if (prevQuestion.dataset.displayed == "false") {
        return this.findPrevQuestion(prevQuestion);
      } else {
        return prevQuestion;
      }
    } else {
      return null;
    }
  }

  lastQuestion() {
    this.formTarget.submit();

    return;
  }

  async setUpTemplateFields(parent, template) {
    var that = this;
    var heading = "How would you like to divide your assets?";
    if (that.productValue) {
      if (that.productValue == "joint_trust") {
        var heading =
          "How would you and your spouse like to divide your assets?";
      }
    }

    var explination = "";
    var tooltip = "";
    if (
      template &&
      template.dataset.name == "receiver_of_assets_after_spouse"
    ) {
      explination =
        "You may leave your estate to individuals or to a charity/organization. Make sure the shares add up to 100%.<br>If you would like to leave money, stock, or property (such as a car) to a charity, you may do so in the next section.";
    }
    if (parent.dataset.questionName == "children") {
      heading = "Add your children below.";
      explination = "";
    }
    if (parent.dataset.questionName == "has_bequests") {
      heading = "Enter the gift/bequest below.";
    }

    // Headings For Assest Category
    if (template && template.dataset.name == "has_real_properties") {
      heading = "List any real estate you own.";
    }
    if (template && template.dataset.name == "has_bank_accounts") {
      heading = "List your bank accounts.";
    }
    if (template && template.dataset.name == "has_business_interests") {
      heading = "List your business interests.";
    }
    if (template && template.dataset.name == "has_financial_accounts") {
      heading = "List your accounts.";
    }
    if (template && template.dataset.name == "has_personal_properties") {
      heading = "List your personal property.";
    }
    if (template && template.dataset.name == "disinherit_children") {
      heading = "Who do you want to exclude from receiving inheritance?";
    }
    if (template && template.dataset.name == "give_charities") {
      heading = "Enter the gift/bequest below.";
    }

    if (
      template &&
      template.dataset.name == "receiver_of_assets_after_spouse"
    ) {
      var user_state = document.querySelector('input[name="user_state"]');
      var user_status = document.querySelector('input[name="user_status"]');
      var reciprocal = document.querySelector('input[name="reciprocal"]');

      if (user_status.value == "Married") {
        explination =
          "You may leave your estate to individuals or to a charity/organization. Make sure the shares add up to 100%.<br>If you would like to leave money, stock, or property (such as a car) to a charity, you may do so in the next section.";
        // if (that.productValue) {
        //   if (that.productValue != "joint_trust") {
        //     explination +=
        //       "<br> <br> <strong>Note:</strong> In New Jersey, you cannot disinherit a spouse. A spouse who has been disinherited has the right to assert an “elective share” against the estate of the deceased spouse.";
        //   }
        // }
        if (user_state.value == "New York") {
          explination =
            "You may leave your estate to individuals or to a charity/organization. Make sure the shares add up to 100%.<br>If you would like to leave money, stock, or property (such as a car) to a charity, you may do so in the next section.";
          if (that.productValue) {
            if (that.productValue != "joint_trust") {
              explination +=
                "<br> <br> <strong>Note:</strong> In New York, you cannot disinherit a spouse.  A spouse who has been disinherited has the right to assert an “elective share” against the estate of the deceased spouse.";
            }
          }
          tooltip = `<span class="tooltip" data-controller="tooltip"> 
                    <div class="img" data-action="click->tooltip#show"><i class="fa-regular fa-circle-question"></i></div>
                    <div class="tooltip-box" data-tooltip-target="tooltipBox">
                    In New York, a surviving spouse is entitled to an elective share, which is a pecuniary amount equal to the greater of (i) $50,000 or, if the capital value of the net estate is less than $50,000, such capital value; or (ii) 1/3 of the net estate.  The net estate means the estate reduced by debts, administration expenses, and reasonable funeral expenses, but disregarding all estate taxes.
                    </div>
                </span>`;
        } else if (user_state.value == "New Jersey") {
          tooltip = `<span class="tooltip" data-controller="tooltip"> 
                    <div class="img" data-action="click->tooltip#show"><i class="fa-regular fa-circle-question"></i></div>
                    <div class="tooltip-box" data-tooltip-target="tooltipBox">
                    In New Jersey, a surviving spouse is entitled to an elective share amount equal to 1/3 of the augmented estate. The augmented estate means the estate reduced by funeral and administration expenses, and enforceable claims, to which is added the value or property transferred by the decedent at any time during the marriage to or for the benefit of any person other than the surviving spouse.
                    </div>
                </span>`;
        }
        if (reciprocal) {
          if (reciprocal.value == "false") {
            explination += `<div class="w-1"><p class="question-explanation"><b>Reminder:</b> You cannot disinherit your spouse in ${user_state.value}. A spouse who has been disinherited has the right to assert an "elective share" against the estate of the deceased spouse.</p></div>`;
          }
        }
      }
    }
    document.querySelector(
      '[data-wizard-target="repeater"]'
    ).innerHTML = `<div class="question-block d-flex flex-wrap w-1">
                    <h2 class="question mb-2">${heading}</h2>
                    ${tooltip}
                    <p class="question-desc">${explination}</p>
                </div>`;
    var that = this;

    parent.classList.remove("show");

    var data = await that.getRepeaterRows();

    var repeater;

    that.repeaterTargets.forEach((el) => {
      if (parent.dataset.questionName == el.dataset.prevQuestion) {
        el.classList.add("show");

        repeater = el;
      } else {
        this.repeaterTarget.classList.add("show");

        repeater = this.repeaterTarget;
      }
    });

    // if (
    //     document.querySelector('[name="marital_status"]') &&
    //     template.dataset.name == "children"
    // ) {
    //     if (document.querySelector('[name="marital_status"]').value != 1) {
    //         template.content
    //             .querySelector(
    //                 '[data-question-name="child_relationship[]"]'
    //             )
    //             .classList.add("hide")

    //         document
    //             .querySelectorAll(
    //                 '[data-question-name="child_relationship[]"]'
    //             )
    //             .forEach((el) => {
    //                 el.classList.add("hide")
    //             })
    //     } else {
    //         template.content

    //             .querySelector(
    //                 '[data-question-name="child_relationship[]"]'
    //             )

    //             .classList.remove("hide")

    //         document

    //             .querySelectorAll(
    //                 '[data-question-name="child_relationship[]"]'
    //             )

    //             .forEach((el) => {
    //                 el.classList.remove("hide")
    //             })
    //     }
    // }

    // repeater.innerHTML = ''

    if (data) {
      if (data.length == that.repeaterBoxTargets.length) {
        that.repeaterBoxTargets.forEach((el) => {
          el.classList.add("show");
        });

        return;
      }

      data.forEach(function (rowData) {
        that.addTemplateRow(rowData, template);
      });

      if (that.repeaterBoxTargets.length == 1) {
        var deleteBtn = that.repeaterBoxTarget.querySelector(".delete-btn");

        deleteBtn.parentNode.removeChild(deleteBtn);
      }

      repeater.insertAdjacentHTML(
        "beforeend",

        '<a data-action="click->wizard#addRow" class="btn btn-more">' +
          that.addValue +
          "</a>"
      );
    } else {
      template.content

        .querySelectorAll(".question-block")

        .forEach(function (q) {
          if (!data) {
            if (
              q.querySelector('[data-wizard-target="answer"]').tagName ==
              "TEXTAREA"
            ) {
              q.querySelector('[data-wizard-target="answer"]').innerHTML = "";
            } else {
              q.querySelector('[data-wizard-target="answer"]').setAttribute(
                "value",

                ""
              );
            }
          }
        });

      var repeaterRow = that.createElement(
        `<div class="repeater-box w-1" data-wizard-target="repeaterBox">` +
          template.innerHTML +
          `</div>

            <a data-action="click->wizard#addRow" class="btn btn-more">` +
          that.addValue +
          `</a>`
      );

      repeater.appendChild(repeaterRow);
    }
  }

  addRow(e) {
    e.preventDefault();

    var repeater = e.target.closest('[data-wizard-target="repeater"]');

    var row = this.resetFields(
      repeater.querySelector(".repeater-box").cloneNode(true)
    );

    var addBtn = repeater.querySelector(".btn");

    row.insertAdjacentHTML(
      "beforeend",

      '<div class="delete-btn" data-action="click->wizard#delete">Delete</div>'
    );

    if (row.querySelector(".secondary-questions")) {
      row.querySelector(".secondary-questions").remove();
    }

    repeater.appendChild(row);

    repeater.appendChild(addBtn);

    var deleteBtn = this.repeaterBoxTarget.querySelector(".delete-btn");

    if (!deleteBtn) {
      this.repeaterBoxTarget.insertAdjacentHTML(
        "beforeend",

        '<div class="delete-btn" data-action="click->wizard#delete">Delete</div>'
      );
    }
  }

  resetFields(row) {
    // reset fields

    var inputs = row.querySelectorAll("input");

    if (inputs) {
      inputs.forEach(function (el) {
        el.value = "";
      });
    }

    var textareas = row.querySelectorAll("textarea");

    if (textareas) {
      textareas.forEach(function (el) {
        el.value = "";
      });
    }

    var selects = row.querySelectorAll("select");

    if (selects) {
      selects.forEach(function (el) {
        el.selectedIndex = 0;
      });
    }

    return row;
  }

  addTemplateRow(data, template) {
    // var template = document.querySelector('template')
    var repeaterRow = "";
    if (template) {
      repeaterRow = this.createElement(
        `<div class="repeater-box" data-wizard-target="repeaterBox">` +
          template.innerHTML +
          `</div>`
      );
    }

    this.repeaterTarget.appendChild(repeaterRow);

    var repeaterBox = this.repeaterTarget.querySelector(
      '[data-wizard-target="repeaterBox"]:last-of-type'
    );

    if (data.id != "") {
      repeaterBox.insertAdjacentHTML(
        "beforeend",

        '<input name="id[]" type="hidden" value="' + data.id + '" />'
      );
    }

    repeaterBox

      .querySelectorAll('[data-wizard-target="answer"]')

      .forEach(function (answer) {
        var name = answer.name.replace("[]", "");

        answer.value = data[name];
      });

    var select = repeaterBox.querySelector(
      'select[data-wizard-target="answer"]'
    );

    if (select) {
      if (select.value != "") {
        this.setupSecondTemplate(select, data);
      }

      select.addEventListener("change", this.showSecondTemplate);
    }

    repeaterBox.insertAdjacentHTML(
      "beforeend",

      '<div class="delete-btn" data-action="click->wizard#delete">Delete</div>'
    );
  }

  async getRepeaterRows() {
    let rows = [];

    const response = await fetch(this.fieldsUrlValue, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",

        "X-CSRF-Token": AUTH_TOKEN,
      },
    })
      .then((response) => response.json())

      .then((data) => {
        rows = data;
      });

    return rows;
  }

  setupSecondTemplate(select, data) {
    var repeaterBox = select.closest('[data-wizard-target="repeaterBox"]');

    var question = select.closest(".question-block");

    var qName = question.dataset.questionName;

    var template = repeaterBox.querySelector(
      'template[data-name="' + qName + '"]'
    );

    if (template) {
      template.content

        .querySelectorAll(".question-block")

        .forEach(function (q) {
          q.classList.remove("in");

          q.classList.add("inactive");

          if (!data) {
            if (
              q.querySelector('[data-wizard-target="answer"]').tagName ==
              "TEXTAREA"
            ) {
              q.querySelector('[data-wizard-target="answer"]').innerHTML = "";
            } else {
              q.querySelector('[data-wizard-target="answer"]').setAttribute(
                "value",

                ""
              );
            }
          }
        });

      var dataProperty =
        qName.split("_")[0] +
        "_" +
        select.selectedOptions[0].text.toLowerCase();

      var innerQuestion =
        qName.split("_")[0] +
        "_" +
        select.selectedOptions[0].text.toLowerCase() +
        "[]";

      var secondaryQuestion = repeaterBox.querySelector(".secondary-questions");

      if (secondaryQuestion) {
        secondaryQuestion.remove();
      }

      var targetQuestion = template.content.querySelector(
        '[data-question-name="' + innerQuestion + '"]'
      );

      targetQuestion.classList.remove("inactive");

      targetQuestion.classList.add("in");

      repeaterBox.insertAdjacentHTML(
        "beforeend",

        '<div class="secondary-questions">' + template.innerHTML + "</div>"
      );

      if (data) {
        var inputs = repeaterBox.querySelectorAll(
          '.secondary-questions [data-wizard-target="answer"]'
        );

        inputs.forEach(function (input) {
          if (input.tagName == "TEXTAREA" && input.name == innerQuestion) {
            input.innerHTML = data[dataProperty];
          } else if (input.tagName == "INPUT" && input.name == innerQuestion) {
            input.setAttribute("value", data[dataProperty]);
          }
        });
      }
    }
  }

  async delete(event) {
    var that = this;

    var repeaterBox = event.target.closest(
      '[data-wizard-target="repeaterBox"]'
    );

    repeaterBox.parentNode.removeChild(repeaterBox);

    if (that.repeaterBoxTargets.length == 1) {
      document.querySelector(".delete-btn").remove();
    }

    var id = repeaterBox.querySelector('[name="id[]"]');

    if (id != null && id.value != "") {
      const response = await fetch(this.deleteUrlValue, {
        method: "DELETE",

        headers: {
          "Content-Type": "application/json",

          "X-CSRF-Token": AUTH_TOKEN,
        },

        body: JSON.stringify({ id: id.value }),
      })
        .then((response) => response.json())

        .then((data) => {
          that.errorMessageTarget.classList.add("alert", "alert-success");

          that.errorMessageTarget.innerHTML = data.msg;
        });
    }
  }

  createElement(str) {
    var frag = document.createDocumentFragment();

    var elem = document.createElement("div");

    elem.innerHTML = str;

    while (elem.childNodes[0]) {
      frag.appendChild(elem.childNodes[0]);
    }

    return frag;
  }

  isVisible(el) {
    return el.offsetParent != null;
  }

  // dropdown functions

  marital_status() {
    var currentQuestion = document.querySelector(
      '[data-question-name="marital_status"]'
    );
    var dropdown = currentQuestion.querySelector("select");
    if (dropdown.value == null || dropdown.value == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");

      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";
    } else if (dropdown.value == 1) {
      currentQuestion.classList.remove("show");
      currentQuestion.nextElementSibling.classList.add("show");
      currentQuestion.nextElementSibling.dataset.displayed = true;
      this.errorMessageTarget.innerText = "";
      this.errorMessageTarget.classList.remove("error-message");
    } else {
      this.displayNextQuestion(currentQuestion);
    }
  }

  bequest_type(e) {
    this.setupSecondTemplate(e.target, false);
  }

  charity_type(e) {
    this.setupSecondTemplate(e.target, false);
  }

  children_distribution_option() {
    var that = this;

    var currentQuestion = document.querySelector(
      '[data-question-name="children_distribution_option"]'
    );

    var dropdown = currentQuestion.querySelector("select");

    if (dropdown.value == null || dropdown.value == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");

      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";
    } else if (dropdown.value == 0) {
      currentQuestion.classList.remove("show");

      document

        .querySelector('[data-question-name="children_distribution_age"]')

        .classList.add("show");

      // this.formTarget.submit()
    } else {
      this.formTarget.submit();
      return false;
    }
  }

  handle_final_arrangements() {
    var currentQuestion = document.querySelector(
      '[data-question-name="handle_final_arrangements"]'
    );

    var dropdown = currentQuestion.querySelector("select");

    if (dropdown.value == null || dropdown.value == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");

      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";
    } else if (dropdown.value == 2) {
      currentQuestion.classList.remove("show");

      currentQuestion.nextElementSibling.classList.add("show");

      currentQuestion.nextElementSibling.dataset.displayed = true;

      this.errorMessageTarget.innerText = "";

      this.errorMessageTarget.classList.remove("error-message");
    } else {
      this.displayNextQuestion(currentQuestion);
    }
  }

  leaveEstateToQuestions(currentQuestion) {
    var that = this;
    var user_children = document.querySelector('input[name="user_children"]');
    if (
      currentQuestion.querySelector("input[type='radio']:checked").value ==
        "0" &&
      user_children.value == "false"
    ) {
      that.backupDistribution(currentQuestion);
      return;
    }
    if (
      currentQuestion.querySelector("input[type='radio']:checked").value == "0"
    ) {
      that.gotoQuestion(currentQuestion, "receiver_of_assets_after_spouse");
    } else if (
      currentQuestion.querySelector("input[type='radio']:checked").value == "1"
    ) {
      that.gotoQuestion(currentQuestion, "children_distribution_option");
    } else {
      var explination = "";
      var tooltipContent = "";
      var user_state = document.querySelector('input[name="user_state"]');
      var user_status = document.querySelector('input[name="user_status"]');

      if (user_status.value == "Married") {
        explination = `<div class="w-1"><p class="question-explanation">Reminder: You cannot disinherit your spouse in ${user_state.value}. A spouse who has been disinherited has the right to assert an "elective share" against the estate of the deceased spouse.</p></div>`;
        if (user_state.value == "New York") {
          tooltipContent = `<span class="tooltip" data-controller="tooltip"> 
                    <div class="img" data-action="click->tooltip#show"><i class="fa-regular fa-circle-question"></i></div>
                    <div class="tooltip-box" data-tooltip-target="tooltipBox">
                    In New York, a surviving spouse is entitled to an elective share, which is a pecuniary amount equal to the greater of (i) $50,000 or, if the capital value of the net estate is less than $50,000, such capital value; or (ii) 1/3 of the net estate.  The net estate means the estate reduced by debts, administration expenses, and reasonable funeral expenses, but disregarding all estate taxes.
                    </div>
                </span>`;
        } else if (user_state.value == "New Jersey") {
          tooltipContent = `<span class="tooltip" data-controller="tooltip"> 
                    <div class="img" data-action="click->tooltip#show"><i class="fa-regular fa-circle-question"></i></div>
                    <div class="tooltip-box" data-tooltip-target="tooltipBox">
                    In New Jersey, a surviving spouse is entitled to an elective share amount equal to 1/3 of the augmented estate. The augmented estate means the estate reduced by funeral and administration expenses, and enforceable claims, to which is added the value or property transferred by the decedent at any time during the marriage to or for the benefit of any person other than the surviving spouse.
                    </div>
                </span>`;
        }
      }
      var questionTxt = document.querySelector(
        '[data-wizard-target="repeater"] h2.question'
      );
      questionTxt.insertAdjacentHTML("afterend", explination);
      questionTxt.insertAdjacentHTML("afterend", tooltipContent);
      that.distribution(currentQuestion);
    }
  }

  receiver_of_assets_after_spouse(currentQuestion) {
    var that = this;
    if (
      document.querySelector(
        'input[name="receiver_of_assets_after_spouse"]:checked'
      ).value == "1"
    ) {
      that.distribution(currentQuestion);
      return false;
    } else if (
      document.querySelector(
        'input[name="receiver_of_assets_after_spouse"]:checked'
      ).value == "2"
    ) {
      document
        .querySelector(
          'input[name="receiver_of_assets_after_children"][value="false"]'
        )
        .setAttribute("checked", true);
      this.formTarget.submit();
      return false;
    } else if (
      document.querySelector(
        'input[name="receiver_of_assets_after_spouse"]:checked'
      ).value == "0"
    ) {
      that.gotoQuestion(currentQuestion, "children_distribution_option");
      return false;
    } else {
      this.formTarget.submit();
      return false;
    }
  }

  backupDistribution(currentQuestion) {
    var that = this;

    var template = document.querySelector(
      'template[data-name="receiver_of_assets_after_spouse"]'
    );

    var repeater = document.querySelector('[data-wizard-target="repeater"]');

    that.setUpTemplateFields(currentQuestion, template);

    repeater.dataset.prevQuestion = currentQuestion.dataset.questionName;
    document.querySelector(
      '[data-wizard-target="repeater"] .question.mb-2'
    ).textContent =
      "If your spouse predeceases you, who should receive your assets?";
  }

  distribution(currentQuestion) {
    var that = this;

    var template = document.querySelector(
      'template[data-name="receiver_of_assets_after_spouse"]'
    );

    var repeater = document.querySelector('[data-wizard-target="repeater"]');

    that.setUpTemplateFields(currentQuestion, template);

    repeater.dataset.prevQuestion = currentQuestion.dataset.questionName;
  }

  has_final_instructions() {
    var currentQuestion = document.querySelector(
      '[data-question-name="has_final_instructions"]'
    );

    var dropdown = currentQuestion.querySelector("select");

    if (dropdown.value == null || dropdown.value == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");
      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";
    } else if (dropdown.value == 3 || dropdown.value == 1) {
      this.formTarget.submit();
      return;
    } else {
      this.displayNextQuestion(currentQuestion);
    }
  }

  resting_place() {
    var currentQuestion = document.querySelector(
      '[data-question-name="resting_place"]'
    );
    var dropdown = currentQuestion.querySelector("select");
    if (dropdown.value == null || dropdown.value == "") {
      this.errorMessageTarget.classList.add("error-message", "alert");
      this.errorMessageTarget.innerHTML =
        "You have to answer the question before moving to the next one! <div class='close-btn'></div>";
    } else if (dropdown.value == 6) {
      currentQuestion.classList.remove("show");
      var targetQuestion = document.querySelector(
        '[data-question-name="funeral_instructions_description"]'
      );
      targetQuestion.classList.add("show");
      targetQuestion.dataset.displayed = true;
    } else {
      this.displayNextQuestion(currentQuestion);
    }
  }

  nextForSelect() {
    var currentQuestion = document.querySelector("div.question-block.show");

    var nextQuestion = this.findNextQuestion(currentQuestion);

    if (nextQuestion != null) {
      this.displayNextQuestion(currentQuestion);
    } else {
      this.formTarget.submit();

      return;
    }
  }
}

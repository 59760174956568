import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "coupon",
    "total",
    "subtotal",
    "discount",
    "paybtn",
    "errorMessage",
  ];

  connect() {
    var cardNo = document.getElementById("card_number");
    if (cardNo) {
      cardNo.addEventListener("input", function (e) {
        e.target.value = e.target.value
          .replace(/[^\dA-Z]/g, "")
          .replace(/(.{4})/g, "$1 ")
          .trim();
      });
    }
  }

  AmexCardnumber(inputtxt) {
    var cardno = /^(?:3[47][0-9]{13})$/;
    return cardno.test(inputtxt);
  }

  VisaCardnumber(inputtxt) {
    var cardno = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    return cardno.test(inputtxt);
  }

  MasterCardnumber(inputtxt) {
    var cardno = /^(?:5[1-5][0-9]{14})$/;
    return cardno.test(inputtxt);
  }

  DiscoverCardnumber(inputtxt) {
    var cardno = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    return cardno.test(inputtxt);
  }

  DinerClubCardnumber(inputtxt) {
    var cardno = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
    return cardno.test(inputtxt);
  }

  JCBCardnumber(inputtxt) {
    var cardno = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
    return cardno.test(inputtxt);
  }

  IsValidCreditCardNumber(e) {
    var cardNumber = e.target.value.replace(/\s/g, "");
    var cardType = null;
    var allImages = document.querySelectorAll(".credit-imgs img");
    allImages.forEach(function (img) {
      img.classList.remove("active");
    });

    if (this.VisaCardnumber(cardNumber)) {
      cardType = "visa";
      document.querySelector(".visa").classList.add("active");
    } else if (this.MasterCardnumber(cardNumber)) {
      cardType = "mastercard";
      document.querySelector(".mastercard").classList.add("active");
    } else if (this.AmexCardnumber(cardNumber)) {
      cardType = "americanexpress";
      document.querySelector(".americanexpress").classList.add("active");
    } else if (this.DiscoverCardnumber(cardNumber)) {
      cardType = "discover";
    } else if (this.DinerClubCardnumber(cardNumber)) {
      cardType = "dinerclub";
    } else if (this.JCBCardnumber(cardNumber)) {
      cardType = "jcb";
    }

    if (cardType == null || e.target.value == "") {
      e.target.classList.remove("success");
      e.target.classList.add("faild");
    } else {
      e.target.classList.remove("faild");
      e.target.classList.add("success");
    }
  }

  show_error(message) {
    var el = document.querySelector(".radio-group");
    if (!document.getElementById("flash-messages")) {
      el.insertAdjacentHTML(
        "afterBegin",
        "<div id='flash-messages' class='alert error-message'></div>"
      );
    }
    var msg = document.getElementById("flash-messages");
    msg.classList.add("alert", "error-message");
    msg.innerHTML = message;
    var title = document.querySelector(".checkout-body .title");
    title.scrollIntoView();
    return false;
  }

  stripeResponseHandler(status, response) {
    var token, $form;
    var loader = document.getElementById("loader");
    $form = document.querySelector("#cc_form");
    if (response.error) {
      this.show_error(response.error.message);
      loader.classList.remove("active");
      document.getElementById("pay-now").setAttribute("disabled", false);
    } else {
      token = response.id;
      $form.querySelector('input[name="token"]').value = token;
      document
        .querySelector('input[name="card_number"]')
        .setAttribute("disabled", true);
      document
        .querySelector('input[name="card_expires_month"]')
        .setAttribute("disabled", true);
      document
        .querySelector('input[name="card_expires_year"]')
        .setAttribute("disabled", true);
      $form.submit();
    }
    return true;
  }

  ccSubmit(event) {
    if (document.querySelector("#total-price").dataset.totalPrice == 0) {
      var allInputs = document.querySelectorAll("#cc_form input");
      allInputs.forEach((element) => {
        element.removeAttribute("required");
      });
    } else {
      if (!this.creditCard()) return;
    }
    var that = this;
    var $form = document.querySelector("#cc_form");
    var loader = document.getElementById("loader");
    // loader.classList.add("active")
    // event.target.setAttribute('disabled', true)
    var total = parseFloat(
      document.querySelector("#total-price").dataset.totalPrice
    );
    //If Stripe was initialized correctly this will create a token using the credit card info
    if (Stripe) {
      if (total > 0) {
        Stripe.card.createToken($form, that.stripeResponseHandler.bind(this));
      } else {
        $form.submit();
      }
    } else {
      console.log(
        "Failed to load credit card processing functionality. Please reload this page in your browser."
      );
    }
    return true;
  }

  async applyCoupon(e) {
    e.preventDefault();
    var that = this;
    if (that.couponTarget.value != "") {
      var fields = {
        coupon: that.couponTarget.value,
      };
      const response = await fetch("/charges/apply-coupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": AUTH_TOKEN,
        },
        body: JSON.stringify(fields),
      })
        .then((response) => response.json())
        .then((data) => {
          var discount_data = data[0];
          var discount,
            total = 0;
          if (discount_data.discount != undefined) {
            var sub_total = parseFloat(
              that.subtotalTarget.innerHTML.replace("$", "").replace(",", "")
            );
            if (discount_data.discount_type == "dollar") {
              discount = discount_data.discount;
              total = sub_total - discount;
            } else {
              discount = (
                (sub_total * parseFloat(discount_data.discount)) /
                100
              ).toFixed(2);
              total = (
                sub_total -
                (sub_total * parseFloat(discount_data.discount)) / 100
              ).toFixed(2);
            }

            that.totalTarget.dataset.totalPrice = total;
            that.discountTarget.innerHTML = "$" + discount;
            that.totalTarget.innerHTML = "$" + total;
            if (total == 0) {
              that.paybtnTarget.innerHTML = "Submit";
            } else {
              that.paybtnTarget.innerHTML = "Pay Now";
            }
          }
          that.errorMessageTarget.classList.remove("alert", "error-message");
          that.errorMessageTarget.innerHTML =
            '<div class="alert ' +
            discount_data.class +
            '">' +
            discount_data.msg +
            "</div>";
        });
    } else {
      that.errorMessageTarget.classList.remove("alert", "error-message");
      that.errorMessageTarget.innerHTML =
        '<div class="alert alert-danger">Please enter valid discount coupon!</div>';
    }
  }

  async deleteOrder(e) {
    var that = this;
    var id = e.target.dataset.orderId;
    var deleteBtn = document.querySelector(
      '.delete-btn[data-order-id="' + id + '"]'
    );
    var row = deleteBtn.closest(".cart-product-row");
    if (id != "") {
      const response = await fetch("/delete-order", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": AUTH_TOKEN,
        },
        body: JSON.stringify({ order_id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          row.remove();
          this.closeModal();
          location.reload();
        });
    }
  }

  deleteModal(e) {
    if (document.querySelector(".delete-modal.delete-popup")) {
      document
        .querySelector(".delete-modal.delete-popup")
        .classList.add("active");
    }
    var id = e.target.dataset.orderId;
    document.querySelector(".delete-modal .delete").dataset.orderId = id;
  }

  closeModal() {
    var deleteModel = document.querySelector(".delete-modal.delete-popup");
    if (deleteModel) {
      deleteModel.classList.remove("active");
      var deleteBtn = document.querySelector(".delete-modal .delete");
      if (deleteBtn) {
        deleteBtn.dataset.orderId = "";
      }
    }
  }
  closeExitIntent(e) {
    var deleteModel = e.target.closest(".delete-modal.exit-intent-popup");
    if (deleteModel) {
      deleteModel.classList.remove("active");
    }
  }
  openModal() {
    var deleteModel = document.querySelector(".delete-modal");
    deleteModel.classList.add("active");
  }

  creditCard() {
    var cardNum = document.getElementById("card_number");
    var expireMonth = document.getElementById("card_expires_month");
    var expireYear = document.getElementById("card_expires_year");
    var cardCVV = document.getElementById("card_cvv");

    if (document.getElementById("credit").checked) {
      if (
        cardNum.value == "" ||
        expireMonth.value == "" ||
        expireYear.value == "" ||
        cardCVV.value == ""
      ) {
        this.errorMessageTarget.classList.add("alert", "error-message");
        this.errorMessageTarget.classList.remove("warning-message");
        this.errorMessageTarget.innerText =
          "Credit Card fields can't be empty.";
        document.querySelector("body").scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return false;
      }
      return true;
    } else {
      cardNum.value = null;
      expireMonth.value = null;
      expireYear.value = null;
      cardCVV.value = null;
      this.errorMessageTarget.classList.add("warning-message");
      this.errorMessageTarget.innerText =
        "Please click on Paypal button to complete the payment.";
      document.querySelector("body").scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
  }

  showPayBtn() {
    document.querySelector("#pay-now").style.opacity = "1";
    document.querySelector("#pay-now").style.pointerEvents = "initial";
  }
  hidePayBtn() {
    document.querySelector("#pay-now").style.opacity = "0";
    document.querySelector("#pay-now").style.pointerEvents = "none";
  }

  // apply family coupon code
  async applyExitIntentCoupon(e) {
    var that = this;
    e.preventDefault();
    var exitIntentModel = e.target.closest(".delete-modal.exit-intent-popup");
    if (exitIntentModel) {
      exitIntentModel.classList.remove("active");
    }
    var fields = {
      coupon: "30MinutesOffer",
    };
    const response = await fetch("/charges/apply-coupon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": AUTH_TOKEN,
      },
      body: JSON.stringify(fields),
    })
      .then((response) => response.json())
      .then((data) => {
        var discount_data = data[0];
        var discount,
          total = 0;
        if (discount_data.discount != undefined) {
          var sub_total = parseFloat(
            that.subtotalTarget.innerHTML.replace("$", "").replace(",", "")
          );
          if (discount_data.discount_type == "dollar") {
            discount = discount_data.discount;
            total = sub_total - discount;
          } else {
            discount = (
              (sub_total * parseFloat(discount_data.discount)) /
              100
            ).toFixed(2);
            total = (
              sub_total -
              (sub_total * parseFloat(discount_data.discount)) / 100
            ).toFixed(2);
          }

          that.totalTarget.dataset.totalPrice = total;
          that.discountTarget.innerHTML = "$" + discount;
          that.totalTarget.innerHTML = "$" + total;
          if (total == 0) {
            that.paybtnTarget.innerHTML = "Submit";
          } else {
            that.paybtnTarget.innerHTML = "Pay Now";
          }
        }
        that.errorMessageTarget.classList.remove("alert", "error-message");
        that.errorMessageTarget.innerHTML =
          '<div class="alert ' +
          discount_data.class +
          '">' +
          discount_data.msg +
          "</div>";
      });
  }
}

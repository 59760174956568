import { Controller } from "stimulus";

export default class extends Controller {
    toggle(event) {
        var currentAccordion = event.target.closest('.accordion-wrapper')
        var allAccordions = document.querySelectorAll('.accordion-sec .accordion-wrapper')

        if (currentAccordion.classList.contains('open')) {
            currentAccordion.classList.remove('open')
            event.target.closest('.accordion').querySelector('.content').style.maxHeight = `${0}px`;
        } else {
            allAccordions.forEach(el => {
                el.classList.remove('open')
                el.querySelector('.content').style.maxHeight = `${0}px`;
            });
            let contentRealHeight = event.target.closest('.accordion').querySelector('.content').scrollHeight
            event.target.closest('.accordion').querySelector('.content').style.maxHeight = `${contentRealHeight + 32}px`;
            currentAccordion.classList.add('open')
        }
    }
}

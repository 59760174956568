import { Controller } from "stimulus"

export default class extends Controller {
    open(event) {
        var subMenu = event.target.querySelector('.sub-menu');
        subMenu.classList.add('open');
    }
    close(event) {
        var subMenu = event.target.closest('.sub-menu');
        subMenu.classList.remove('open');
    }
}
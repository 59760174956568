import Rails from "@rails/ujs";
import "controllers";
Rails.start();

document.addEventListener("DOMContentLoaded", function () {

  // List Animation
  if (document.querySelector('.necessary-sec')) {
    let lastScrollPosition = 0;
    let items = document.querySelectorAll('.necessary-sec .necessary-list li')

    document.addEventListener("scroll", (event) => {
      lastScrollPosition = window.scrollY;
      let height = window.innerHeight;

      items.forEach(el => {
        if (lastScrollPosition >= el.offsetTop - (height / 2)) {
          el.classList.add('active')
        } else {
          el.classList.remove('active')
        }
      });
    });
  }

  // Home Slider Scroll
  var allSlides = document.querySelectorAll('.home-slider .swiper-slide .card')
  var slider_answer = document.querySelector('#slider_answer')
  allSlides.forEach(el => {
    el.addEventListener('click', function(e) {
      slider_answer.scrollIntoView();
    })
  });

  // Home Slider
  var swiper = new Swiper(".home-slider", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    clickable: true,	
		// loop: true,
    coverflowEffect: {
      rotate: 0,
			stretch: 70,
			depth: 90,
			modifier: 1.35,
			slideShadows : false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false,
    // },
  });

  // Alert Message
  window.setInterval(function () {
    if (document.querySelector(".alert.alert-success")) {
      document.querySelector(".alert").innerHTML = "";
      document.querySelector(".alert").classList.remove("alert-success");
    }
    if (document.querySelector(".alert.error-message")) {
      document.querySelector(".alert").innerHTML = "";
      document.querySelector(".alert").classList.remove("error-message");
    }
  }, 5000);

  window.setInterval(function () {
    if (document.querySelector(".alert.alert-success .close-btn")) {
      document
        .querySelector(".alert.alert-success .close-btn")
        .addEventListener("click", function (e) {
          e.target.closest(".alert").classList.remove("alert-success");
          e.target.closest(".alert").innerHTML = "";
        });
    }
    if (document.querySelector(".alert.error-message")) {
      document
        .querySelector(".alert.error-message .close-btn")
        .addEventListener("click", function (e) {
          e.target.closest(".alert").classList.remove("error-message");
          e.target.closest(".alert").innerHTML = "";
        });
    }
  }, 1000);
});


// CookieBar
class CookieBar {
  constructor() {
    this.cookiesBar = document.getElementById('cookies-bar');
  }

  init() {
    this.addButtonBehaviors();
  }

  addButtonBehaviors() {
    if (!this.cookiesBar) {
      return;
    }
    this.cookiesBar.querySelector('.accept').addEventListener('click', () => this.allowCookies(true));
    this.cookiesBar.querySelector('.accept').addEventListener('click', () => this.cookiesBar.style.display = "none");
    this.cookiesBar.querySelector('.close-btn').addEventListener('click', () => this.cookiesBar.style.display = "none");
  }

  allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 365
      });
    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 365
      });
    }
    this.cookiesBar.classList.add('hidden');
  }
}

window.onload = function () {
  const cookieBar = new CookieBar();
  cookieBar.init();
}
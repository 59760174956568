import { add, Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["pdfContent", "timer", "countdown"];
  static values = { editWill: String, closeEdit: String, generateUrl: String };

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "ReviewsChannel",
        id: this.data.get("id"),
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
    this.timer();
  }

  timer() {
    var counterEl = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="countdown"]'
    );

    counterEl.forEach((el) => {
      var tab = document.querySelector(".tabs-container .active");
      if (tab) {
        if (!tab.classList.contains("clicked")) {
          tab.classList.add("clicked");
          var counter = 60;
          var interval = setInterval(function () {
            counter--;
            el.innerHTML = counter;
            if (counter == 0) {
              clearInterval(interval);
            }
          }, 1000);
        }
      }
    });
  }

  show(e) {
    e.preventDefault();
    var href = e.target.dataset.id;
    var el = document.getElementById(href);
    var pdf_container = document.querySelector(".pdf-body.active .pdf-img");
    pdf_container.scrollTop = el.offsetTop - 344;
    var allImgs = document.querySelectorAll(
      ".pdf-body.active .side-preview img"
    );
    allImgs.forEach(function (img) {
      img.classList.remove("active");
    });
    e.target.classList.add("active");
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    var html = `<a class="close-btn" data-action="reviews#closeFullPreview">
                        <i class="fa-solid fa-xmark"></i>
                    </a>`; //document.querySelector('#' + data.id + '.pdf-body div[data-reviews-target="pdfContent"]').innerHTML
    var images_arr = data.html;
    images_arr.forEach(function (item, index) {
      html +=
        "<img src='" +
        item +
        "' id=" +
        data.id +
        "_preview-image-" +
        index +
        " />";
    });
    document
      .querySelector(
        "#" + data.id + '.pdf-body div[data-reviews-target="timer"]'
      )
      .classList.add("hide");
    document
      .querySelector(".pdf-body.active .buttons-wrapper .download-btn")
      .classList.remove("disable");
    document
      .querySelector(".pdf-body.active .buttons-wrapper .full-preview-btn")
      .classList.remove("disable");
    document
      .querySelector("#" + data.id + ".pdf-body .pdf-container")
      .classList.add("show");
    document.querySelector(
      "#" + data.id + '.pdf-body div[data-reviews-target="pdfContent"]'
    ).innerHTML = html;
    var pdf_imgs = document.querySelectorAll(
      "#" + data.id + ".pdf-body .pdf-img img"
    );

    if (document.querySelector(".pdf-body .side-preview")) {
      pdf_imgs.forEach(function (img) {
        var id = img.id;
        var src = img.src;
        document
          .querySelector("#" + data.id + ".pdf-body .side-preview")
          .insertAdjacentHTML(
            "beforeend",
            '<img data-id="' +
              id +
              '" src="' +
              src +
              '" alt="" data-action="click->reviews#show">'
          );
      });

      document
        .querySelectorAll("#" + data.id + ".pdf-body .side-preview img")[0]
        .classList.add("active");
    }
  }

  menuOpen(e) {
    document.querySelector(".pdf-page").classList.toggle("open");
    e.target.classList.toggle("active");
    if (e.target.innerHTML == this.editWillValue) {
      e.target.innerHTML = this.closeEditValue;
    } else {
      e.target.innerHTML = this.editWillValue;
    }
  }
  menuClose(e) {
    var edit_btn = document.querySelector(".show-menu-btn");
    document.querySelector(".pdf-page").classList.toggle("open");
    edit_btn.classList.toggle("active");

    if (edit_btn.innerHTML == this.editWillValue) {
      edit_btn.innerHTML = this.closeEditValue;
    } else {
      edit_btn.innerHTML = this.editWillValue;
    }
  }

  openTab(e) {
    var id = e.target.dataset.target;
    var all_tabs = document.querySelectorAll(".tabs-container .tab");
    var all_pdf_body = document.querySelectorAll(".pdf-body");

    all_tabs.forEach(function (el) {
      el.classList.remove("active");
    });

    all_pdf_body.forEach(function (el) {
      el.classList.remove("active");
    });

    e.target.classList.add("active");
    document.querySelector("#" + id).classList.add("active");
  }

  activateTimer(e) {
    var that = this;
    var imagesPopluated = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="pdfContent"] img'
    ).length
      ? true
      : false;
    if (!imagesPopluated) {
      that.timer(e);
    }
  }

  generateTrustWill(e) {
    var imagesPopluated = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="pdfContent"] img'
    ).length
      ? true
      : false;

    if (imagesPopluated) return;

    fetch("/trust/reviews/generate-trust-will", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": AUTH_TOKEN,
      },
    });
    this.activateTimer(e);
  }

  generateJointTrustWill(e) {
    var imagesPopluated = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="pdfContent"] img'
    ).length
      ? true
      : false;

    if (imagesPopluated) return;

    fetch("/joint-trust/reviews/generate-joint-trust-will", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": AUTH_TOKEN,
      },
    });
    this.activateTimer(e);
  }

  generateJointTrustSpouseWill(e) {
    var imagesPopluated = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="pdfContent"] img'
    ).length
      ? true
      : false;

    if (imagesPopluated) return;

    fetch("/joint-trust/reviews/generate-joint-trust-spouse-will", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": AUTH_TOKEN,
      },
    });
    this.activateTimer(e);
  }

  generateSpouseWill(e) {
    var imagesPopluated = document.querySelectorAll(
      '.pdf-body.active [data-reviews-target="pdfContent"] img'
    ).length
      ? true
      : false;

    if (imagesPopluated) return;

    fetch("/will/reviews/generate-spouse-will", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": AUTH_TOKEN,
      },
    });
    this.activateTimer(e);
  }

  fullPreview(e) {
    document
      .querySelector(".pdf-body.active .pdf-img")
      .classList.add("full-preview");
  }
  closeFullPreview() {
    document
      .querySelector(".pdf-body.active .pdf-img")
      .classList.remove("full-preview");
  }

  openPaymentModal() {
    document.querySelector(".payment-modal").classList.add("active");
  }

  closePaymentModal() {
    document.querySelector(".payment-modal").classList.remove("active");
  }

  openModal() {
    document.querySelector(".attorney-modal").classList.add("active");
  }

  closeModal() {
    document.querySelector(".attorney-modal").classList.remove("active");
  }

  closeAlert() {
    document.querySelector(".alert-wrapper").style.display = "none";
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
    convertPercent33(event) {
        var percentInput = event.target
        var val = percentInput.value
        var KeyID = event.keyCode;
        val = val.replace(/[^0-9.]/g, '')
        if(val % 1 != 0 && val.length == 5){
          val = parseFloat(val).toFixed(2)
        }
        if (KeyID == 8) {
          val = val + '%'
          val = val.slice(0, val.length - 2) + val.slice(val.length - 1)
          if (val == '%' || val == '') {
            val = ''
          }
        } else {
          if (val == '%' || val == '') {
            val = ''
          } else if (val > 33.33) {
            val = '33.33%';
          } else {
            val = val + '%'
          }
        }
        percentInput.value = val
    }

    convertPercent(event) {
      var percentInput = event.target
      var val = percentInput.value
      var KeyID = event.keyCode;
      val = val.replace(/[^0-9.]/g, '')

      if(val % 1 != 0 && val.length == 5){
        val = parseFloat(val).toFixed(2)
      }
      if (KeyID == 8) {
        val = val + '%'
        val = val.slice(0, val.length - 2) + val.slice(val.length - 1)
        if (val == '%' || val == '') {
          val = ''
        }
      } else {
        if (val == '%' || val == '') {
          val = ''
        } else if (val > 100) {
          val = '100%';
        } else {
          val = val + '%'
        }
      }
      percentInput.value = val
  }
}
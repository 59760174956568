import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        // this.setupSpouseName()
        this.changeName()
    }

    changeName() {
        var input = document.querySelectorAll("input")
        input.forEach(function (el) {
            var name = el.getAttribute("name")
            if (el.name == "charity_name[]") {
                el.setAttribute("placeholder", "Organization Name")
            } else if (el.name == "financial_account_name[]") {
                el.setAttribute("placeholder", "Account Type")
            } else if (el.name == "investment_company[]") {
                el.setAttribute("placeholder", "Investment Company")
            } else if (el.name == "heirs_name[]") {
                el.setAttribute("placeholder", "Person or Organization")
            } else if (el.name == "business_name[]") {
                return
            }else if (el.name.includes("name") || el.name.includes("Name")) {
                if (el.name.includes("first") || el.name.includes("middle") || el.name.includes("last") || el.name.includes("Bank") || el.name.includes("bank")) {
                    return;
                }
                else {
                    el.setAttribute("placeholder", "First and Last Name")
                    const str = el.value
                    const arr = str.split(" ")
                    for (var i = 0; i < arr.length; i++) {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
                    }
                    const str2 = arr.join(" ")
                    el.value = str2                    
                }
            }
        })
    }

    // spouseName() {
    //     var input = document.querySelector('input[name="spouse_name"]')
    //     var parent = input.closest('.answer')
    //     var first_name = parent.querySelector('input[name="first_name"]')
    //     var middle_name = parent.querySelector('input[name="middle_name"]')
    //     var last_name = parent.querySelector('input[name="last_name"]')

    //     input.setAttribute('value', first_name.value + ' ' + middle_name.value + ' ' + last_name.value)
    // }

    // setupSpouseName() {
    //     var input = document.querySelector('input[name="spouse_name"]')
    //     if (input != null) {
    //         var parent = input.closest('.answer')
    //         parent.innerHTML = parent.innerHTML +
    //             `<div class="three-fileds">
    //                 <input class="input-field" type="text" name="first_name" placeholder="First Name"" data-action="input->names#spouseName input->names#changeName" data-questions-target="answer">
    //                 <input class="input-field" type="text" name="middle_name" placeholder="Middle Name" data-action="input->names#spouseName input->names#changeName" data-optional="true" data-questions-target="answer">
    //                 <input class="input-field" type="text" name="last_name" placeholder="Last Name" data-action="input->names#spouseName input->names#changeName" data-questions-target="answer">
    //             </div>`
    //         document.querySelector('input[name="spouse_name"]').setAttribute('type', 'hidden')

    //         var first_name = parent.querySelector('input[name="first_name"]')
    //         var middle_name = parent.querySelector('input[name="middle_name"]')
    //         var last_name = parent.querySelector('input[name="last_name"]')

    //         if (input.value != '') {
    //             const arr = input.value.split(" ")
    //             var first_name_val = arr[0]
    //             var middle_name_val = ''
    //             var last_name_val = ''

    //             if (arr[1] === undefined) {
    //                 middle_name_val = ''
    //             } else {
    //                 middle_name_val = arr[1]
    //             }

    //             if (arr[2] === undefined) {
    //                 last_name_val = ''
    //             } else {
    //                 last_name_val = arr[2]
    //             }

    //             if (arr.length == 2) {
    //                 first_name_val = arr[0]
    //                 middle_name_val = ''
    //                 last_name_val = arr[1]
    //             } else if (arr.length > 3) {
    //                 for (let i = 2; i < arr.length; i++) {
    //                     last_name_val = last_name_val + ' ' + arr[i]
    //                 }
    //             }

    //             first_name.value = first_name_val
    //             middle_name.value = middle_name_val
    //             last_name.value = last_name_val
    //         }
    //     }
    // }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["wizard", "answer", "errorMessage", "form"]
    static values = { backUrl: String }
    connect() {
        var firstQuestion = this.wizardTarget.querySelector('.question-block')
        firstQuestion.dataset.displayed = true
        firstQuestion.classList.add('show')
    }

    getAnswer() {
        var answers = []
        var question = document.querySelector('.question-block.show')
        var elements = question.querySelectorAll('[data-ny-target="answer"]')
        elements.forEach((el, i) => {
            var answer = -1
            if (el.type == 'radio') {
                if (el.checked == true) {
                    answer = el.value
                    answers.push(answer)
                }
            }
        })
        if (answers.includes(-1) || answers.includes("") || answers.length == 0) {
            return -1
        } else {
            return true
        }
    }

    validateAnswer() {
        var answer = -1
        answer = this.getAnswer()
        if (answer == -1 || answer == "") {
            this.errorMessageTarget.classList.add('error-message')
            this.errorMessageTarget.innerText = "You have to answer the question before moving to the next one!"
            return false
        } else {
            return true
        }
    }

    displayNextQuestion(currentQuestion) {
        var nextQuestion = this.findNextQuestion(currentQuestion)
        var tooltip = document.querySelector('.tooltip-menu')
        if (tooltip != null) {
            tooltip.classList.remove('active')
        }
        if (nextQuestion) {
            currentQuestion.classList.remove('show')
            nextQuestion.classList.add('show')
            nextQuestion.dataset.displayed = true
        } else {
            this.formTarget.submit()
        }

    }

    findNextQuestion(currentQuestion) {
        var nextQuestion = currentQuestion.nextElementSibling
        this.errorMessageTarget.innerText = ""
        this.errorMessageTarget.classList.remove('error-message')

        if (nextQuestion) {

            if (nextQuestion.dataset.questionName == 'any_children') {
                return nextQuestion
            }
            if (nextQuestion.dataset.questionName == 'parents') {
                var spouse = document.querySelector('[data-question-name="spouse"]')
                var anyChildren = document.querySelector('[data-question-name="any_children"]')
                var spouseValue = ''
                var anyChildrenValue = ''

                if (anyChildren.querySelector('[data-ny-target="answer"]:checked')) {
                    anyChildrenValue = anyChildren.querySelector('[data-ny-target="answer"]:checked').value
                }
                if (spouse.querySelector('[data-ny-target="answer"]:checked')) {
                    spouseValue = spouse.querySelector('[data-ny-target="answer"]:checked').value
                }

                if (spouseValue == 'false' && anyChildrenValue == 'false') {
                    return nextQuestion
                } else {
                    return this.findNextQuestion(nextQuestion)
                }
            }
            if (nextQuestion.dataset.questionName == 'paternal_uncles') {
                var grandparents = document.querySelector('[data-question-name="grandparents"]')
                var grandparentsValue = ''
                if (grandparents.querySelector('[data-ny-target="answer"]:checked')) {
                    grandparentsValue = grandparents.querySelector('[data-ny-target="answer"]:checked').value
                }

                if (grandparentsValue == 'false') {
                    return nextQuestion
                } else {
                    return this.findNextQuestion(nextQuestion)
                }
            }
            if (nextQuestion.dataset.questionName == 'maternal_uncles') {
                var grandparents = document.querySelector('[data-question-name="grandparents"]')
                var grandparentsValue = ''
                if (grandparents.querySelector('[data-ny-target="answer"]:checked')) {
                    grandparentsValue = grandparents.querySelector('[data-ny-target="answer"]:checked').value
                }

                if (grandparentsValue == 'false') {
                    return nextQuestion
                } else {
                    return this.findNextQuestion(nextQuestion)
                }
            }
        
            if (nextQuestion.dataset.parentId != '') {
                var parentId = nextQuestion.dataset.parentId
                var parentAnswer = nextQuestion.dataset.parentAnswer
                var parentQuestion = document.querySelector('[data-question-id="' + parentId + '"]')
                var input = false
                if(parentQuestion.querySelector('[data-ny-target="answer"]:checked')){
                    input = parentQuestion.querySelector('[data-ny-target="answer"]:checked')
                }

                if (parentAnswer && input.value) {
                    if (parentAnswer == input.value) {
                        return nextQuestion
                    } else {
                        return this.findNextQuestion(nextQuestion)
                    }
                } else {
                    return this.findNextQuestion(nextQuestion)
                }
            } else {
                return nextQuestion
            }
        } else {
            return null
        }
    }

    next(e) {
        e.preventDefault()
        if (!this.validateAnswer()) return
        this.errorMessageTarget.innerText = ""
        this.errorMessageTarget.classList.remove('error-message')
        var currentQuestion = document.querySelector('.question-block.show')
        this.displayNextQuestion(currentQuestion)
    }

    prev(e) {
        e.preventDefault()
        var currentQuestion = document.querySelector('.question-block.show')
        if(currentQuestion.querySelector('[data-ny-target="answer"]:checked')){
            currentQuestion.querySelector('[data-ny-target="answer"]:checked').checked = false
        }
        var prevQuestion = this.findPrevQuestion(currentQuestion)
        this.errorMessageTarget.innerHTML = ""
        this.errorMessageTarget.classList.remove('error-message')
        if (prevQuestion) {
            currentQuestion.classList.remove('show')
            currentQuestion.dataset.displayed = false
            prevQuestion.classList.add('show')
        } else {
            document.location.href = this.backUrlValue
        }
    }

    findPrevQuestion(currentQuestion) {
        var prevQuestion = currentQuestion.previousElementSibling
        if (prevQuestion) {
            if (prevQuestion.dataset.displayed == 'false') {
                return this.findPrevQuestion(prevQuestion)
            } else {
                return prevQuestion
            }
        } else {
            return null
        }
    }
}

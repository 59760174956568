import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["popup"]

    connect() {
        var sec = 1800,
            countDiv = document.getElementById("timer"),
            secpass,
            countDown = setInterval(function () {
                secpass();
            }, 1000);

        function secpass() {
            var min = Math.floor(sec / 60),
                remSec = sec % 60;
            if (remSec < 10) {
                remSec = '0' + remSec;
            }
            if (min < 10) {
                min = '0' + min;
            }
            countDiv.innerHTML = min + ":" + remSec;

            if (sec > 0) {
                sec = sec - 1;
            } else {
                clearInterval(countDown);
                countDiv.innerHTML = 'countdown done';
            }
        }

    }

    openModal(e) {
        var popup = document.querySelector('.modal')
        popup.querySelector('.image').src = '/calculator/' + e.target.dataset.img
        popup.classList.add('open')
    }

    closeModal() {
        this.popupTarget.classList.remove('open')
    }

    close(e) {
        if (e.target == this.popupTarget) {
            this.popupTarget.classList.remove('open')
        }
    }

    closePopUp() {
        this.popupTarget.classList.add('hide')
    }

}
import { Controller } from "stimulus"

export default class extends Controller {

    connect(){
        var el = document.querySelector('textarea[name="final_instructions"]')
        var container = el.closest('.textarea-container')
        var newEl = '<div class="text-info"><div>Total word count: <span id="display_count">0</span> words.</div> <div><span id="word-left-container">Words left: <span id="word_left">500</span></span></div></div>'
        var text_info = container.querySelector('.text-info')
        if(!text_info){
            el.insertAdjacentHTML('afterend', newEl);
        }
        document.addEventListener('keyup', this.check)
    }

    check(){
      var that = document.querySelector('textarea[name="final_instructions"]')
      var container = that.closest('.textarea-container')
      
      if (that.length) {
        var words = 0;
        var el = that;
        if ((el.val().match(/\S+/g)) != null) {
          words = el.val().match(/\S+/g).length;
        }
        if (words > 500) {
          // Split the string on first 200 words and rejoin on spaces
          var trimmed = el.val().split(/\s+/, 500).join(" ");
          // Add a space at the end to make sure more typing creates new words
          el.val(trimmed + " ");
        }
        else {
          container.querySelector('#display_count').text(words);
          container.querySelector('#word_left').text(500 - words);
        }
      }
      that.addEventListener('keyup', function (e) {
        var words = 0;
        if ((this.value.match(/\S+/g)) != null) {
          words = this.value.match(/\S+/g).length;
        }
        if (words > 500) {
          // Split the string on first 200 words and rejoin on spaces
          var trimmed = that.value.split(/\s+/, 500).join(" ");
          // Add a space at the end to make sure more typing creates new words
          that.value = (trimmed + " ");
        }
        else {
          container.querySelector('#display_count').innerHTML = (words);
          container.querySelector('#word_left').innerHTML = (500 - words);
        }
      });
    }
  }
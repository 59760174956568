import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    var header = document.querySelector(".menu-side");
    header.classList.toggle("open");
    document.querySelector(".menu-open-btn").classList.toggle("open");
  }

  toggleSideMenu(e) {
    var page = e.target.closest(".wizard-page");
    page.classList.toggle("closed-side-menu");
  }
}

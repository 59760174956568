import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    var allForms = document.querySelectorAll(".form");

    // allForms.forEach((el) => {
    //   var allInputs = el.querySelectorAll(".field-group");
    //   allInputs.forEach((el) => {
    //     el.querySelector(".placeholder").innerHTML =
    //       el.querySelector(".input-field").dataset.placeholder;
    //   });
    // });
  }

  placeholder(event) {
    event.target.closest(".field-group").classList.add("active");
    event.target.closest(".field-group").classList.add("arr");
  }

  onType(event) {
    if (event.target.value != "" || event.target.selectedIndex > 0) {
      event.target.closest(".field-group").classList.add("active");
      this.validation(event);
    } else {
      event.target.closest(".field-group").classList.remove("active");
    }
    event.target.closest(".field-group").classList.remove("arr");
  }

  validation(event) {
    var answers = [];
    var elements = event.target
      .closest("form")
      .querySelectorAll(".input-field");

    elements.forEach((el) => {
      if (el.type == "text" && el.value != "") {
        answers.push(el.value);
      } else if (el.type == "textarea" && el.value != "") {
        answers.push(el.value);
      } else if (el.tagName == "SELECT" && el.selectedIndex > 0) {
        answers.push(el.value);
      } else if (el.type == "email" && validateEmail(el.value)) {
        answers.push(el.value);
      } else if (el.type == "password" && el.value != "") {
        answers.push(el.value);
      } else {
        answers.push(-1);
      }
    });
    if (answers.includes(-1)) {
      event.target
        .closest(".form")
        .querySelector(".btn")
        .classList.add("disabled");
      event.target
        .closest(".form")
        .querySelector(".btn")
        .classList.remove("main-btn");
    } else {
      event.target
        .closest(".form")
        .querySelector(".btn")
        .classList.add("main-btn");
      event.target
        .closest(".form")
        .querySelector(".btn")
        .classList.remove("disabled");
    }
  }
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["tooltipBox"];

    show(e) {
        var text = this.tooltipBoxTarget.innerHTML
        document.querySelector('.tooltip-menu').classList.add('active')
        document.querySelector('.tooltip-menu .text').innerHTML = text
    }
    hide() {
      document.querySelector('.tooltip-menu').classList.remove('active')
    }
}
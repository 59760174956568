import { Controller } from "stimulus"

export default class extends Controller {

    find(event) {
        const input = event.target
        if (this._autocomplete == undefined) {
            this._autocomplete = new google.maps.places.Autocomplete(input)
        }
        return this._autocomplete
    }
}
import { Controller } from "stimulus"

export default class extends Controller {
    connect(){
        
    }
    
    onlyNumbers(e){
        if (e.target.value != '') {
            if (isNaN(e.target.value)) {
                e.target.value = "";
                return false;
            } else {
                return true
            }
        }
    }
}